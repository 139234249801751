/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Helmet from 'react-helmet';
const DnsPrefetch = () => {
  return (
    <Helmet>
      <link href="https://cdns.eu1.gigya.com/" rel="dns-prefetch" />
      <link href="https://cdns.gigya.com/" rel="dns-prefetch" />
      <link href="https://connect.facebook.net/" rel="dns-prefetch" />
      <link href="https://platform.twitter.com/" rel="dns-prefetch" />
      <link href="https://assets.adobedtm.com" rel="dns-prefetch" />
      <link href="https://securepubads.g.doubleclick.net" rel="dns-prefetch" />
      <link href="https://tpc.googlesyndication.com" rel="dns-prefetch" />
      <link href="https://pagead2.googlesyndication.com" rel="dns-prefetch" />
      <link href="https://www.facebook.com" rel="dns-prefetch" />
      <link href="https://players.brightcove.net" rel="dns-prefetch" />
      <link href="https://www.google.com" rel="dns-prefetch" />
      <link href="https://www.googletagservices.com" rel="dns-prefetch" />
      <link href="https://www.gstatic.com" rel="dns-prefetch" />
    </Helmet>
  );
};

export default React.memo(DnsPrefetch);
