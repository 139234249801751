/**
 *  © 2024 -2025 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const SelectEComProductFooter = (data, isAws) => {
  return {
    rendering: {
      fields: {
        EComProduct1: {
          value: {
            EComProduct1: {
              ShopID:
                data &&
                data.SelectEComProductFooter[0] &&
                data.SelectEComProductFooter[0]?.fields?.EComProduct1?.value?.EComProduct1?.ShopID,
              entities:
                data &&
                data.SelectEComProductFooter[0] &&
                data.SelectEComProductFooter[0]?.fields?.EComProduct1?.value?.EComProduct1
                  ?.entities,
              Theme:
                data &&
                data.SelectEComProductFooter[0] &&
                data.SelectEComProductFooter[0]?.fields?.EComProduct1?.value?.EComProduct1?.Theme,
              EcomProductApiURL:
                data &&
                data.SelectEComProductFooter[0] &&
                data.SelectEComProductFooter[0]?.fields?.EComProduct1?.value?.EComProduct1
                  ?.EcomProductApiURL,
              Limit:
                data &&
                data.SelectEComProductFooter[0] &&
                data.SelectEComProductFooter[0]?.fields?.EComProduct1?.value?.EComProduct1?.Limit,
              Sort:
                data &&
                data.SelectEComProductFooter[0] &&
                data.SelectEComProductFooter[0]?.fields?.EComProduct1?.value?.EComProduct1?.Sort,
              SortDirection:
                data &&
                data.SelectEComProductFooter[0] &&
                data.SelectEComProductFooter[0]?.fields?.EComProduct1?.value?.EComProduct1
                  ?.SortDirection,
            },
          },
        },
        Title: {
          value:
            data &&
            data.SelectEComProductFooter[0] &&
            data.SelectEComProductFooter[0]?.fields?.Title?.value,
        },
        ViewAllCTAText: {
          value:
            data &&
            data.SelectEComProductFooter[0] &&
            data.SelectEComProductFooter[0]?.fields?.ViewAllCTAText?.value,
        },
        ViewAllCTADestinationurl: {
          value: {
            anchor:
              data &&
              data.SelectEComProductFooter[0] &&
              data.SelectEComProductFooter[0]?.fields?.ViewAllCTADestinationurl?.value?.anchor,
            href:
              data &&
              data.SelectEComProductFooter[0] &&
              data.SelectEComProductFooter[0]?.fields?.ViewAllCTADestinationurl?.value?.href,
            hrefMuCustom:
              data &&
              data.SelectEComProductFooter[0] &&
              data.SelectEComProductFooter[0]?.fields?.ViewAllCTADestinationurl?.value
                ?.hrefMuCustom,
            linktype:
              data &&
              data.SelectEComProductFooter[0] &&
              data.SelectEComProductFooter[0]?.fields?.ViewAllCTADestinationurl?.value?.linktype,
            target:
              data &&
              data.SelectEComProductFooter[0] &&
              data.SelectEComProductFooter[0]?.fields?.ViewAllCTADestinationurl?.value?.target,
            url:
              data &&
              data.SelectEComProductFooter[0] &&
              data.SelectEComProductFooter[0]?.fields?.ViewAllCTADestinationurl?.value?.url,
          },
        },
        BackGroundThemeImage: {
          value: {
            Crops:
              data &&
              data.SelectEComProductFooter[0] &&
              data.SelectEComProductFooter[0]?.fields?.BackGroundThemeImage?.value?.Crops,
            Preview:
              data &&
              data.SelectEComProductFooter[0] &&
              data.SelectEComProductFooter[0]?.fields?.BackGroundThemeImage?.value?.Preview,
            isWebpAvailable:
              data &&
              data.SelectEComProductFooter[0] &&
              data.SelectEComProductFooter[0]?.fields?.BackGroundThemeImage?.value?.isWebpAvailable,
          },
        },
      },
    },
  };
};
