/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  encodeURLParams,
  setCookie,
  getParameterByName,
  checkSubscription,
  createDestinationUrl,
  isApp,
} from './utils';
import { createCodeVerifierNChallenge } from '../lib/PKCE';
import MU from '../constants/muConstants';
import { showLogin, showRegister } from './gigyaScreen';
import { onUserLogout } from '../components/MyUnited/unitedUtils';
import { SET_USER_PROFILE } from '../constants/endpoints';
import api from '../utils/muApi';
import secureLocalStorage from 'react-secure-storage';
import { handleAuthClick } from './calendarUtils';
import axios from 'axios';
import xss from 'xss';

// References
// https://auth0.com/docs/get-started/authentication-and-authorization-flow/add-login-using-the-authorization-code-flow-with-pkce
// https://www.stefaanlippens.net/oauth-code-flow-pkce.html

export const createBaseUrl = () =>
  typeof window !== 'undefined' && window.location.origin + '/' + MU.language;

const loginRedirectUrl = typeof window !== 'undefined' && window.location.href;
const cookiesTimeout = 365;

export const redirectUrl = () => {
  if (loginRedirectUrl) {
    const code = getParameterByName('code', true);
    if (code) {
      return loginRedirectUrl.includes('?') ? loginRedirectUrl.split('?')[0] : createBaseUrl();
      // } else if (loginRedirectUrl.slice(-1) !== '/') {
      //   return loginRedirectUrl + '/';
    } else return loginRedirectUrl;
  } else {
    return createBaseUrl();
  }
};

export const generateCodes = () =>
  createCodeVerifierNChallenge().then(({ verifier, challenge }) => {
    setIDMCookie('idm_verifier', verifier);
    setIDMCookie('idm_challenge', challenge);
    return challenge;
  });

const createLoginUrl = (challenge, successCallback) => {
  if (successCallback) {
    const url1 = `${
      MU.iDMLoginURL
    }/connect/authorize?client_id=web&scope=openid full_profile offline_access status ticketing auth&response_type=code&prompt=login&redirect_uri=${redirectUrl()}&culture=${langParameters()}&code_challenge_method=S256&code_challenge=${challenge}&state=${successCallback}`;
    console.log('IDM 13. createLoginUrl', url1);
    return url1;
  } else {
    const url2 = `${
      MU.iDMLoginURL
    }/connect/authorize?client_id=web&scope=openid full_profile offline_access status ticketing auth&response_type=code&prompt=login&redirect_uri=${redirectUrl()}&culture=${langParameters()}&code_challenge_method=S256&code_challenge=${challenge}`;
    console.log('IDM 14. createLoginUrl', url2);
    return url2;
  }
};

const createRefreshLoginUrl = (challenge) => {
  const url = `${
    MU.iDMLoginURL
  }/connect/authorize?client_id=web&scope=openid full_profile offline_access status ticketing auth&response_type=code&prompt=none&redirect_uri=${redirectUrl()}&culture=${langParameters()}&code_challenge_method=S256&code_challenge=${challenge}`;
  console.log('IDM 12. createRefreshLoginUrl', url);
  return url;
};
const createMUTVUrl = (challenge) =>
  `${
    MU.iDMLoginURL
  }/connect/authorize?client_id=web_tv&scope=openid full_profile offline_access status ticketing auth&response_type=code&redirect_uri=${redirectUrl()}&culture=${langParameters()}&code_challenge_method=S256&code_challenge=${challenge}&state=web_tv`;

const createSignupUrl = (challenge) => createLoginUrl(challenge) + '&acr_values=action:register';

const createMUTVSignupUrl = (challenge) => createMUTVUrl(challenge) + '&acr_values=action:register';

const createLogoutUrl = () => {
  const url =
    `${
      MU.iDMLoginURL
    }/connect/endsession?post_logout_redirect_uri=${createBaseUrl()}&culture=${langParameters()}&id_token_hint=` +
    getIDMCookie('idm_id_token');
  console.log('IDM 15. createLogoutUrl', url);
  return url;
};

export const checkLocalCodeMissing = () => !getIDMCookie('idm_code');

const setIDMUserSession = (eventObj) => {
  localStorage.setItem('deviceID', eventObj && eventObj.UIDSignature);
};

const storeUserData = (data) => {
  if (isApp()) {
    localStorage.setItem('vyaktigat', JSON.stringify(data));
    localStorage.setItem('vyaktigat_time', new Date().getTime());
  } else {
    secureLocalStorage.setItem('userInfo', JSON.stringify(data));
    secureLocalStorage.setItem('userInfo_time', new Date().getTime());
  }
  setIDMUserSession(data);
};

export const removeUserData = () => {
  if (isApp()) {
    localStorage.removeItem('vyaktigat');
    localStorage.removeItem('vyaktigat_time');
  } else {
    secureLocalStorage.removeItem('userInfo');
    secureLocalStorage.removeItem('userInfo_time');
  }
};

export const userDataExpireTime = (value, userDataLogOutTime) => {
  // const mainValue = value.toLowerCase();
  // switch (mainValue) {
  //   case 'mutv':
  //     return 1;
  //   case 'myunited':
  //     return 0;
  //   default:
  //     return 5;
  // }
  if (userDataLogOutTime) {
    if (typeof userDataLogOutTime === 'string') {
      const integerValue = parseInt(userDataLogOutTime);
      if (integerValue) {
        return integerValue / 60;
      }
    } else if (userDataLogOutTime) {
      return userDataLogOutTime / 60;
    }
  }
  return 0;
};
export const getUserData = (value) => {
  let getUserLocalData;
  if (isApp()) {
    getUserLocalData = localStorage.getItem(value);
  } else {
    getUserLocalData = secureLocalStorage.getItem(value);
  }
  return JSON.parse(getUserLocalData);
};

export const fetchIDMToken = (code, _cbS, _cbF, code_verifier, currentPage, client_id) => {
  console.log('IDM 6. fetchIDMToken', code, code_verifier, currentPage, client_id);
  axios({
    url: `${MU.iDMLoginURL}/connect/token`,
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', accept: 'application/json' },
    data: encodeURLParams({
      client_id: client_id,
      grant_type: 'authorization_code',
      code: code,
      code_verifier: code_verifier,
      redirect_uri: redirectUrl(),
    }),
  })
    .then((response) => {
      console.log('IDM 7. fetchIDMTokenResponse', response);
      const { error, access_token, refresh_token, id_token } = response.data;
      if (access_token) {
        setIDMCookie('idm_access_token', access_token);
        console.log('IDM 7a. idm_access_token', access_token);
      }
      if (refresh_token) {
        setIDMCookie('idm_refresh_token', refresh_token);
        console.log('IDM 7b. idm_refresh_token', refresh_token);
      }
      if (id_token) {
        setIDMCookie('idm_id_token', id_token);
        console.log('IDM 7c. idm_id_token', id_token);
      }
      eraseIDMCookie('idm_verifier');
      eraseIDMCookie('idm_challenge');

      if (error) {
        console.log('IDM 7d. token_error', error);
        if (currentPage === 'myunited') window.location = createBaseUrl();
        else {
          console.log('IDM 7e. token_fail to call newToken');
          _cbF();
        }
      } else {
        console.log('IDM 7f. token_sucess to call fetchUserProfileInfo');
        _cbS();
      }
    })
    .catch((err) => {
      eraseIDMCookie('idm_verifier');
      eraseIDMCookie('idm_challenge');
      _cbF();
      console.log(err.message);
    });
};

export const getNewToken = (refresh_token, _cb, currentPage, client_id) => {
  console.log('IDM 16. fetchIDMToken', refresh_token, currentPage, client_id);
  axios({
    url: `${MU.iDMLoginURL}/connect/token`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      accept: 'application/json',
    },
    data: encodeURLParams({
      client_id: client_id,
      grant_type: 'refresh_token',
      refresh_token: refresh_token,
    }),
  })
    .then((response) => {
      console.log('IDM 16a. getNewTokenResponse', response);
      const { error, access_token, refresh_token, id_token } = response.data;
      if (access_token) {
        setIDMCookie('idm_access_token', access_token);
        console.log('IDM 16b. idm_access_token', access_token);
      }
      if (refresh_token) {
        setIDMCookie('idm_refresh_token', refresh_token);
        console.log('IDM 16c. idm_refresh_token', refresh_token);
      }
      if (id_token) {
        setIDMCookie('idm_id_token', id_token);
        console.log('IDM 16d. idm_id_token', id_token);
      }
      if (error) {
        afterLogout();
        console.log('IDM 16e. newtoken_error', error);
        if (currentPage === 'myunited') window.location = createBaseUrl();
      } else {
        console.log('IDM 16f. newtoken_sucess to call fetchUserProfileInfo');
        _cb();
      }
    })
    .catch((err) => {
      afterLogout();
      console.log(err.message);
      window.location = createBaseUrl();
    });
};

export const fetchUserInfo = (savedAccessToken, _cbS, _cbF) => {
  console.log('IDM 11. fetchUserInfo', savedAccessToken);
  axios({
    url: `${MU.iDMLoginURL}/connect/userinfo`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      accept: 'application/json',
      Authorization: 'Bearer ' + savedAccessToken,
    },
  })
    .then((response) => {
      storeUserData(response.data);
      _cbS(response.data);
      console.log(response.data);
    })
    .catch((err) => {
      console.log(err.message);
      console.log('IDM 11b. error_fetchUserInfoResponse', err.message);
      _cbF();
    });
};

export const redirectToIDMLogin = (check, successCallback) => {
  generateCodes().then((challenge) => {
    if (typeof window !== 'undefined') {
      if (check === 'page_refresh') {
        window.location = createRefreshLoginUrl(challenge);
      } else {
        window.location = createLoginUrl(challenge, successCallback);
      }
    }
  });
};

export const afterLogout = () => {
  eraseIDMCookie('idm_code');
  eraseIDMCookie('idm_verifier');
  eraseIDMCookie('idm_challenge');
  eraseIDMCookie('idm_access_token');
  eraseIDMCookie('idm_refresh_token');
  eraseIDMCookie('idm_id_token');
};

export const redirectToIDMSignUp = () => {
  generateCodes().then((challenge) => {
    if (typeof window !== 'undefined') window.location = createSignupUrl(challenge);
  });
};

export const contentLoginFlow = (getLoginDetail) => {
  let successCallback;
  if (getLoginDetail) {
    successCallback = getLoginDetail();
  }
  redirectToIDMLogin('', successCallback);
};

export const redirectToIDMMUTVSignUp = () => {
  generateCodes().then((challenge) => {
    if (typeof window !== 'undefined') window.location = createMUTVSignupUrl(challenge);
  });
};

export const triggerSignUp = (getLoginDetail, isMutv) => {
  if (MU.isIDMEnabled) {
    redirectToIDMSignUp();
  } else {
    showRegister(getLoginDetail, isMutv);
  }
};

export const checkIDMFlow = (isUserLogin, code) => {
  if (isUserLogin && checkLocalCodeMissing()) {
    redirectToIDMLogin();
  }
  if (code) {
    if (typeof window !== 'undefined') {
      const url = window.location.href;
      const separator = url.includes('fanforumrepresentativecontact') ? '&' : '?';
      window.location = xss(
        url
          ? url.split(separator)[0]
            ? url.split(separator)[0]
            : createBaseUrl()
          : createBaseUrl()
      );
    }
  }
};

export const IDMSuccessScroll = (retriveSuccessCallback, userData) => {
  const successCallData = retriveSuccessCallback.split(',');
  if (successCallData && successCallData[0] === 'successScroll') {
    const isSubscribedContent = successCallData[1] && successCallData[1] === 'true';
    const userSubscribed = checkSubscription(userData);
    if (!isSubscribedContent || (isSubscribedContent && userSubscribed)) {
      window.scrollTo(Number(successCallData[2]), Number(successCallData[3]) - 300);
      const url = window.location.href;
      const urlMain = url.split('?')[0] ?? url;
      window.history.replaceState({}, null, urlMain);
    }
  }
};

export const IDMSuccessCallback = (retriveSuccessCallback, userData) => {
  const successCallback = retriveSuccessCallback.split(',');
  if (successCallback && successCallback[0] === 'successCallbck') {
    const isSubscribedContent = successCallback[1] && successCallback[1] === 'true';
    const userSubscribed = checkSubscription(userData);
    if (!isSubscribedContent || (isSubscribedContent && userSubscribed)) {
      if (successCallback[2] === 'destinationUrl') {
        if (successCallback[3] && typeof window !== 'undefined')
          window.location = createDestinationUrl(successCallback[3]);
        else window.location = redirectUrl();
      } else if (successCallback[2] === 'googleCalendarUrl') {
        if (successCallback[3]) handleAuthClick(successCallback[3]);
        else window.location = redirectUrl();
      } else if (successCallback[2] === 'openDestinationUrl') {
        if (successCallback[3] && successCallback[4] && typeof window !== 'undefined')
          window.open(successCallback[3], successCallback[4]);
        else window.location = redirectUrl();
      } else window.location = redirectUrl();
    } else window.location = redirectUrl();
  } else window.location = redirectUrl();
};

export const doUserLogout = () => {
  if (typeof window !== 'undefined' && MU.isIDMEnabled) {
    removeUserData();
    window.location = xss(createLogoutUrl());
    afterLogout();
  } else {
    onUserLogout();
  }
};

export const triggerLoginFlow = (getLoginDetail, isMutv) => {
  MU.isIDMEnabled ? redirectToIDMLogin() : showLogin(getLoginDetail, isMutv);
};

export const createEditProfileUrl = () =>
  `${
    MU.iDMLoginURL
  }/profile?culture=${langParameters()}&returnUrl=${createBaseUrl()}/myunited/settings`;

export const createChangePasswordUrl = () =>
  `${
    MU.iDMLoginURL
  }/change-password?culture=${langParameters()}&returnUrl=${createBaseUrl()}/myunited/settings`;

export const createChangeEmaildUrl = () =>
  `${
    MU.iDMLoginURL
  }/change-email?culture=${langParameters()}&returnUrl=${createBaseUrl()}/myunited/settings`;

export const changePreferencesUrl = () =>
  `${MU.iDMLoginURL}/change-preferences?culture=${langParameters()}`;

const langParameters = () => {
  const lang = MU.language;
  switch (lang) {
    case 'en': {
      return 'en-GB';
    }
    case 'fr': {
      return 'fr-FR';
    }
    case 'de': {
      return 'de-DE';
    }
    case 'es': {
      return 'es-ES';
    }
    case 'it': {
      return 'it-IT';
    }
    case 'zh': {
      return 'zh-Hans';
    }
    case 'ko': {
      return 'ko-KR';
    }
    case 'ja': {
      return 'ja-JP';
    }
    case 'ar': {
      return 'ar-SA';
    }
    default:
      return 'en-GB';
  }
};

export const setUserFavPlayer = (userLoginDetail, favPlayerId) => {
  const route = `${SET_USER_PROFILE}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}/${MU.language}/`;
  const header = { Accept: 'application/json' };
  const isHardcoredGeo = false;
  const data = userFavPlayerDataObj(userLoginDetail, favPlayerId);
  return api
    .post({ baseURL, route, isHardcoredGeo, header, data })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log('userInfo POST error', err);
    });
};
const userFavPlayerDataObj = (userLoginDetail, favPlayerId) => {
  let userObj = {
    UID: userLoginDetail && userLoginDetail.UID && userLoginDetail.UID,
    UIDSignature: userLoginDetail && userLoginDetail.UIDSignature && userLoginDetail.UIDSignature,
    signatureTimestamp:
      userLoginDetail && userLoginDetail.signatureTimestamp && userLoginDetail.signatureTimestamp,
    data: {
      favoritePlayerID: favPlayerId && favPlayerId,
    },
  };
  return userObj;
};

export const setUserJerseyNumber = (userLoginDetail, JerseyNo) => {
  const route = `${SET_USER_PROFILE}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}/${MU.language}/`;
  const header = { Accept: 'application/json' };
  const isHardcoredGeo = false;
  const data = userJerseyNumberDataObj(userLoginDetail, JerseyNo);
  return api
    .post({ baseURL, route, isHardcoredGeo, header, data })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log('userInfo POST error', err);
    });
};
const userJerseyNumberDataObj = (userLoginDetail, JerseyNo) => {
  let userObj = {
    UID: userLoginDetail && userLoginDetail.UID && userLoginDetail.UID,
    UIDSignature: userLoginDetail && userLoginDetail.UIDSignature && userLoginDetail.UIDSignature,
    signatureTimestamp:
      userLoginDetail && userLoginDetail.signatureTimestamp && userLoginDetail.signatureTimestamp,
    data: {
      jerseyNo: JerseyNo && JerseyNo,
    },
  };
  return userObj;
};

export const saveUserIDCookie = (uid) => {
  if (!MU.isIDMEnabled && uid) {
    setCookie('manutd_uid', uid, cookiesTimeout, ';domain=.manutd.com');
  }
};

export const removeUserIDCookie = () => {
  if (document) {
    document.cookie =
      'manutd_uid=; Path=/; domain=.manutd.com;  expires=Thu, 01 Jan 1970 00:00:00 UTC';
  }
};

export const setIDMCookie = (name, value, days) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
  localStorage.setItem(name, JSON.stringify(value));
};
export const getIDMCookie = (name) => {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
export const eraseIDMCookie = (name) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  localStorage.removeItem(name);
};
