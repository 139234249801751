/**
 *  © 2024 -2025 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const ExternalProductCarousel = (data, isAws) => {
  return {
    rendering: {
      fields: {
        EComProduct1: {
          value: {
            EComProduct1: {
              ShopID: data?.fields?.EComProduct1?.value?.EComProduct1?.ShopID,
              entities: data?.fields?.EComProduct1?.value?.EComProduct1?.entities,
              Theme: data?.fields?.EComProduct1?.value?.EComProduct1?.Theme,
              EcomProductApiURL: data?.EComProduct1?.value?.EComProduct1?.EcomProductApiURL,
              Limit: data?.EComProduct1?.value?.EComProduct1?.Limit,
              Sort: data?.EComProduct1?.value?.EComProduct1?.Sort,
              SortDirection: data?.EComProduct1?.value?.EComProduct1?.SortDirection,
            },
          },
        },
        Title: {
          value: data?.fields?.Title?.value,
        },
        ViewAllCTAText: {
          value: data?.fields?.ViewAllCTAText?.value,
        },
        ViewAllCTADestinationurl: {
          value: {
            anchor: data?.fields?.ViewAllCTADestinationurl?.value?.anchor,
            href: data?.fields?.ViewAllCTADestinationurl?.value?.href,
            hrefMuCustom: data?.fields?.ViewAllCTADestinationurl?.value?.hrefMuCustom,
            linktype: data?.fields?.ViewAllCTADestinationurl?.value?.linktype,
            target: data?.fields?.ViewAllCTADestinationurl?.value?.target,
            url: data?.fields?.ViewAllCTADestinationurl?.value?.url,
          },
        },
        BackGroundThemeImage: {
          value: {
            Crops: data?.fields?.BackGroundThemeImage?.value?.Crops,
            Preview: data?.fields?.BackGroundThemeImage?.value?.Preview,
            isWebpAvailable: data?.fields?.BackGroundThemeImage?.value?.isWebpAvailable,
          },
        },
      },
    },
  };
};
