/**
 *  © 2024 -2025 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const EComProductFooter = (data, isAws) => {
  return {
    rendering: {
      fields: {
        EComProduct1: {
          value: {
            EComProduct1: {
              ShopID: data?.EComProductFooter?.value?.EComProductFooter?.ShopID,
              entities: data?.EComProductFooter?.value?.EComProductFooter?.entities,
              Theme: data?.EComProductFooter?.value?.EComProductFooter?.Theme,
              EcomProductApiURL:
                data?.EComProductFooter?.value?.EComProductFooter?.EcomProductApiURL,
              Limit: data?.EComProductFooter?.value?.EComProductFooter?.Limit,
              Sort: data?.EComProductFooter?.value?.EComProductFooter?.Sort,
              SortDirection: data?.EComProductFooter?.value?.EComProductFooter?.SortDirection,
            },
          },
        },
        Title: {
          value: data?.EComProductFooterTitle?.value,
        },
        ViewAllCTAText: {
          value: data?.EComProductFooterViewAllCTAText?.value,
        },
        ViewAllCTADestinationurl: {
          value: {
            anchor: data?.EComProductFooterViewAllCTADestinationurl?.value?.anchor,
            href: data?.EComProductFooterViewAllCTADestinationurl?.value?.href,
            hrefMuCustom: data.EComProductFooterViewAllCTADestinationurl?.value?.hrefMuCustom,
            linktype: data?.EComProductFooterViewAllCTADestinationurl?.value?.linktype,
            target: data?.EComProductFooterViewAllCTADestinationurl?.value?.target,
            url: data?.EComProductFooterViewAllCTADestinationurl?.value?.url,
          },
        },
        BackGroundThemeImage: {
          value: {
            Crops: data?.EComProductFooterImage?.value?.Crops,
            Preview: data?.EComProductFooterImage?.value?.Preview,
            isWebpAvailable: data?.EComProductFooterImage?.value?.isWebpAvailable,
          },
        },
      },
    },
  };
};
